import React from 'react';
import { Link, PageProps } from 'gatsby';

import Layout from '../components/layout';

export default function Home(props: PageProps) {
  return (
    <Layout title="Home" mainClasses="sm:px-6  mx-auto max-w-screen-xl px-4">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Software to enrich your
          <br className="xl:hidden" />
          <span className="xl:p-2 text-brandblue">transit agency</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          We bring together best-in-class software development practices with
          the world of public transit information systems, resulting in
          cost-efficient software without sacrificing rider experience.
        </p>
        {/* <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        We build bridges, not moats, around your data, empowering your agency to make data-driven decisions. 
      </p> */}
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:mr-3">
            <Link
              to="/plans"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-brandblue bg-white hover:text-brandlightblue focus:outline-none focus:border-brandlightblue focus:shadow-outline-brandgreen transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              See plans
            </Link>
          </div>
          <div className="rounded-md shadow">
            <Link
              to="/contact"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brandblue hover:bg-brandlightblue focus:outline-none focus:border-brandlightblue focus:shadow-outline-brandgreen transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
